@font-face {
	font-family: 'cgos';
	src:url('../assets/fonts/cgos.eot?-qajlks');
	src:url('../assets/fonts/cgos.eot?#iefix-qajlks') format('embedded-opentype'),
		url('../assets/fonts/cgos.ttf?-qajlks') format('truetype'),
		url('../assets/fonts/cgos.woff?-qajlks') format('woff'),
		url('../assets/fonts/cgos.svg?-qajlks#cgos') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="cgos-icon-"], [class*=" cgos-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'cgos' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; 
}

.cgos-icon-logement:before {
    content: "\e900";
}
.cgos-icon-portail:before {
    content: "\e600";
}
.cgos-icon-accueil:before {
    content: "\48";
}
.cgos-icon-famille:before {
    content: "\50";
}
.cgos-icon-mini-famille:before {
    content: "\70";
}
.cgos-icon-retraite:before {
    content: "\52";
}
.cgos-icon-mini-retraite:before {
    content: "\72";
}
.cgos-icon-loisirs:before {
    content: "\4c";
}
.cgos-icon-mini-loisirs:before {
    content: "\6c";
}
.cgos-icon-vacances:before {
    content: "\56";
}
.cgos-icon-mini-vacances:before {
    content: "\76";
}
.cgos-icon-conso:before {
    content: "\43";
}
.cgos-icon-mini-conso:before {
    content: "\63";
}
.cgos-icon-contact:before {
    content: "\40";
}
.cgos-icon-agents:before {
    content: "\24";
}
.cgos-icon-cgos-info:before {
    content: "\21";
}
.cgos-icon-cgos:before {
    content: "\2e";
}

