.jvectormap-label {
	position: absolute;
	display: none;
	border: solid 1px #CDCDCD;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	background: #292929;
	color: white;
	font-family: sans-serif, Verdana;
	font-size: smaller;
	padding: 3px;
}
.jvectormap-zoomin,
.jvectormap-zoomout {
	display: none;
/*
	position: absolute;
 left:10px;
 -webkit-border-radius:3px;
 -moz-border-radius:3px;
 border-radius:3px;
 background:#292929;
 padding:3px;
 color:white;
 width:10px;
 height:10px;
 cursor:pointer;
 line-height:10px;
 text-align:center;
*/
}
.jvectormap-zoomin {
	top: 10px;
}
.jvectormap-zoomout {
	top: 30px;
}