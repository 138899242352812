/** initial setup **/
.nano {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 10px; }
  .nano .nano-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: scroll;
    overflow-x: hidden; }
    .nano .nano-content:focus {
      outline: thin dotted; }
    .nano .nano-content::-webkit-scrollbar {
      display: none; }
  .nano > .nano-pane {
    width: 8px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    visibility: hidden\9;
    opacity: .01; }
    .nano > .nano-pane > .nano-slider {
      background: #2C3E50;
      background: rgba(44, 62, 80, 0.5);
      position: relative;
      margin: 0 1px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px; }

.has-scrollbar > .nano-content::-webkit-scrollbar {
  display: block; }

.nano:hover > .nano-pane, .nano-pane.active, .nano-pane.flashed {
  visibility: visible\9;
  opacity: 0.99; }
